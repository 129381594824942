import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StaticContent from "./pages/StaticContent";
import NoContent from "./pages/NoContent";

function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route
          path="content/:migrationPathName/:moduleName/:stepName/:version"
          element={<StaticContent />}
        />
        <Route path="*" element={<NoContent />} />
      </Routes>
    </Router>
  );
}

export default App;
