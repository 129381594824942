import {
  getPathfinderServiceEndpoint,
  pathfinderAxios,
} from "../utils/axiosUtils";

export type GetMigrationContentParams = {
  migrationPathName?: string;
  moduleName?: string;
  stepName?: string;
  version?: string;
};

export type GetMigrationContentResponse = {
  content: string;
};

export async function getMigrationContent(
  params: GetMigrationContentParams
): Promise<GetMigrationContentResponse> {
  const response = await pathfinderAxios.post<GetMigrationContentResponse>(
    getPathfinderServiceEndpoint("getMigrationContent"),
    params
  );

  return response.data;
}
