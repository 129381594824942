import { useEffect, useState } from "react";
import { getMigrationContent } from "../service/migrationContentService";
import { useParams } from "react-router-dom";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "github-markdown-css";
import BasePage from "./BasePage";
import axios from "axios";
import rehypeRaw from "rehype-raw";

function StaticContent() {
  // create content state
  const [content, setContent] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(true);

  const { migrationPathName, moduleName, stepName, version } = useParams();
  useEffect(() => {
    const getContent = async () => {
      try {
        setBusy(true);
        const response = await getMigrationContent({
          migrationPathName,
          moduleName,
          stepName,
          version,
        });
        setContent(response.content);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setErrorMessage(e.response?.data?.message || "");
        }
        setError(true);
        console.error("Error in getting the step content", e);
      } finally {
        setBusy(false);
      }
    };
    getContent();
  }, [migrationPathName, moduleName, stepName, version]);
  return (
    <BasePage busy={busy} error={error} errorMessage={errorMessage}>
      <div className="markdown-body" data-testid="content">
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw] as any}
          components={{ a: LinkRenderer }}
        >
          {content || ""}
        </Markdown>
      </div>
    </BasePage>
  );
}

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

export default StaticContent;
