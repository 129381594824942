import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { AuthenticatedArea } from "./components/auth/AuthenticatedArea";
import { getCognitoConfig } from "./configs/configUtils";
import { addAxiosInterceptors, pathfinderAxios } from "./utils/axiosUtils";

addAxiosInterceptors(pathfinderAxios);

Amplify.configure({
  Auth: getCognitoConfig(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthenticatedArea>
      <App />
    </AuthenticatedArea>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
