import { Auth } from "aws-amplify";
import { AxiosInstance } from "axios";
import { getApiBasePath } from "../configs/configUtils";
import axios from "axios";

export const pathfinderAxios = axios.create({
  baseURL: getApiBasePath(),
  headers: {
    "Content-Type": "application/json",
  },
});

export function addAxiosInterceptors(axiosInstance: AxiosInstance): void {
  axiosInstance.interceptors.request.use(
    async (config) => {
      // Get the current session from Amplify
      const session = await Auth.currentSession();
      // Get the access token or the ID token from the session
      const token = session.getIdToken().getJwtToken();
      // Set the token in the authorization header
      config.headers.Authorization = token;
      // Return the modified config object
      return config;
    },
    (error) => {
      // Handle any request error here
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      // Return the response data here
      return response;
    },
    async (error) => {
      // Handle any response error here
      if (error.response) {
        // The request was made and the server responded with an error status code
        console.error(error.response.status, error.response.data); // e.g. 401 or 403 { message: 'Unauthorized' }
        // You can handle different errors based on the status code or the message
        if (error.response.status === 401) {
          // The token is expired or invalid, you can refresh it or sign out the user
          await Auth.signOut();
        } else if (error.response.status === 403) {
          // The user is not authorized to access the resource, you can show an error message or redirect them to another page
          alert("You are not allowed to access this resource");
        }
      } else if (error.request) {
        // The request was made but no response was received, you can check the network connection or retry later
        console.error(error.request);
      } else {
        // Something else happened, you can check the error message or log it for debugging
        console.error(error.message);
      }
      return Promise.reject(error);
    }
  );
}

export function getPathfinderServiceEndpoint(operation: string): string {
  return `/${operation}`;
}
