import BasePage from "./BasePage";
import "./NoContent.css";

function NoContent() {
  return (
    <BasePage busy={false}>
      <div className="App">
        <header className="App-header">
          <p>404 - No Content Found in this Path</p>
        </header>
      </div>
    </BasePage>
  );
}

export default NoContent;
